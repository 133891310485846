import { decode, encode } from "base-64";
if (!global.btoa) {
	global.btoa = encode;
}
if (!global.atob) {
	global.atob = decode;
}
import FlashMessage from "react-native-flash-message";
import React from "react";
import {
	Platform,
	StatusBar,
	StyleSheet,
	View,
	Permissions,
	LogBox,
} from "react-native";
import Colors from "./constants/Colors";
import FirebaseProduction from "./constants/Firebase";
import FirebaseTest from "./constants/FirebaseTest";
import * as firebase from "firebase";
import AppNavigator from "./navigation/AppNavigator";
import Constants from "expo-constants";

import "./App.css";

// For recording videos or showcase
// LogBox.ignoreAllLogs(true);
export default class App extends React.Component {
	state = {
		loadedSession: false,
	};

	async UNSAFE_componentWillMount() {
		if (!firebase.apps.length) {
			const firebaseConfig =
				Constants.appOwnership != "expo"
					? FirebaseProduction
					: FirebaseTest;
			firebase.initializeApp(firebaseConfig);
		}
	}

	render() {
		return (
			<View style={styles.container}>
				{/* {Platform.OS === 'ios' && <StatusBar barStyle="light-content" />}
				{Platform.OS === 'android' && <StatusBar barStyle="light-content" backgroundColor={Colors.tintColor} />}
				<AppNavigator />
				<FlashMessage position="top" />  */}

				<header>
					<img
						src="https://www.semil.com.br/img/logo.webp"
						alt="Logo da Empresa"
						class="logo"
					/>
					<h1>Atenção:</h1>
				</header>
				<div className="container">
					<div class="aviso">
						<p>Este serviço foi descontinuado.</p>
						<p>
							<a href="https://semil.com.br" target="_blank">
								Clique aqui para voltar à página inicial
							</a>
						</p>
					</div>
				</div>

				<footer>
					<p>&copy; 2024 - Semil - Equipamentos Industriais</p>
				</footer>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor,
	},
});
